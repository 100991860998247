<template>
  <van-image :src="showImg" />
</template>

<script>
import { Image } from 'vant';

export default {
  name: 'Avatar',

  props: {
    src: {
      required: true,
    },
    defaultSrc: [String, Object],
    errorSrc: [String, Object],
  },

  data() {
    return {
      showImg: '',
    };
  },

  watch: {
    src: {
      handler: 'changeImg',
      immediate: true,
    },
    defaultSrc: {
      handler() {
        this.changeImg();
      },
      immediate: true,
    },
  },

  methods: {
    changeImg(img) {
      if (img) {
        this.showImg = img;
      } else {
        this.showImg = this.src || this.defaultSrc || this.errorSrc;
      }
    },
  },

  components: {
    [Image.name]: Image,
  },
};
</script>
