import * as dayjs from 'dayjs';

export default function filters() {
  const hiddenTel = (val) => {
    if (typeof val === 'string' && /^\d{11}$/.test(val)) {
      return val.replace(/^(\d{3})\d{4}(\d{4})$/gi, '$1****$2');
    } else {
      return '暂无';
    }
  };

  const hiddenEmail = (val) => {
    if (typeof val === 'string' && /^.+@.+\..+$/.test(val)) {
      return val.replace(/^(\w?)(\w+)(\w)(@.+)$/gi, '$1****$3$4');
    } else {
      return '暂无';
    }
  };

  const salaryText = (val) => {
    return (
      ['', '', '3-4K', '4-5k', '5-10k', '10-20k', '20-50k', '50k以上'][val] ||
      ''
    );
  };

  const eduText = (val) => {
    return ['', '', '专科', '本科', '硕士', '博士'][val] || '';
  };

  const richText = (val) => {
    return (val || '')
      .replace(/</gi, '&lt;')
      .replace(/>/gi, '&gt;')
      .replace(/\n/gi, '<br/>');
  };

  const formattedDate = (val) => {
    return dayjs(val).format('YYYY-MM-DD') || '';
  };

  return {
    hiddenTel,
    hiddenEmail,
    richText,
    salaryText,
    eduText,
    formattedDate,
  };
}
