<template>
  <div class="page">
    <van-sticky>
      <div class="student-info">
        <div class="flex-box info-main">
          <yxz-avatar
            :src="studentInfo.avatarUrl"
            :defaultSrc="
              studentInfo.gender == 2
                ? require('@/assets/images/stu-female.png')
                : require('@/assets/images/stu-male.png')
            "
            class="avatar"
          />

          <div class="avatar-right van-ellipsis flex-box column between">
            <span class="name van-ellipsis">{{ studentInfo.realname }}</span>

            <div class="school flex-box middle">
              <yxz-avatar
                v-if="studentInfo.schoolLogo"
                :src="studentInfo.schoolLogo"
                class="school-logo"
              ></yxz-avatar>
              <span class="van-ellipsis">{{ studentInfo.schoolName }}</span>
            </div>
          </div>
        </div>

        <div class="flex-box middle info-line" v-if="studentInfo.mobile">
          <van-icon name="phone-o" />
          <span>{{ studentInfo.mobile }}</span>
        </div>

        <div class="flex-box middle info-line" v-if="studentInfo.email">
          <van-icon name="envelop-o" />
          <span>{{ studentInfo.email }}</span>
        </div>
      </div>
    </van-sticky>

    <div class="card" v-if="preferList.length">
      <div class="card-head van-hairline--bottom flex-box middle">
        <yxz-avatar
          class="card-icon"
          :src="require('@/assets/images/prefer-icon.png')"
        />
        <span>求职意向</span>
      </div>

      <div class="card-main">
        <div class="item" v-for="item in preferList" :key="item.id">
          <div class="item-name flex-box middle van-ellipsis">
            <span class="van-ellipsis">{{ item.professionType }}</span>
            <span>{{ salaryText(item.salary) }}</span>
          </div>
          <div class="item-text flex-box middle van-ellipsis">
            <span class="van-ellipsis">{{ item.industryName }}</span>
            <span class="van-ellipsis">{{ item.cityName }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="educationList.length">
      <div class="card-head van-hairline--bottom flex-box middle">
        <yxz-avatar
          class="card-icon"
          :src="require('@/assets/images/education-icon.png')"
        />
        <span>教育经历</span>
      </div>

      <div class="card-main">
        <div class="item" v-for="item in educationList" :key="item.id">
          <div class="item-name flex-box middle van-ellipsis">
            <span class="van-ellipsis">{{ item.schoolName }}</span>
            <span>{{ eduText(item.educationBackground) }}</span>
            <span class="van-ellipsis">{{ item.major }}</span>
          </div>

          <div class="item-text flex-box middle van-ellipsis">
            <span class="van-ellipsis">{{ item.startDate }}</span>
            <span>-</span>
            <span class="van-ellipsis">{{ item.endDate }}</span>
          </div>

          <div class="item-text">{{ item.content }}</div>
        </div>
      </div>
    </div>

    <div class="card" v-if="experienceList.length">
      <div class="card-head van-hairline--bottom flex-box middle">
        <yxz-avatar
          class="card-icon"
          :src="require('@/assets/images/experience-icon.png')"
        />
        <span>实习/工作经历</span>
      </div>

      <div class="card-main">
        <div class="item" v-for="item in experienceList" :key="item.id">
          <div class="item-name flex-box middle van-ellipsis">
            <span class="van-ellipsis">{{ item.companyName }}</span>
          </div>

          <div class="item-text flex-box middle van-ellipsis">
            <span class="van-ellipsis">{{ item.startDate }}</span>
            <span>-</span>
            <span class="van-ellipsis">{{ item.endDate }}</span>
          </div>

          <div class="item-text">{{ item.content }}</div>
        </div>
      </div>
    </div>

    <div class="card" v-if="positionList.length">
      <div class="card-head van-hairline--bottom flex-box middle">
        <yxz-avatar
          class="card-icon"
          :src="require('@/assets/images/position-icon.png')"
        />
        <span>校内职务</span>
      </div>

      <div class="card-main">
        <div class="item" v-for="item in positionList" :key="item.id">
          <div class="item-name flex-box middle van-ellipsis">
            <span class="van-ellipsis">{{ item.jobName }}</span>
          </div>

          <div class="item-text flex-box middle van-ellipsis">
            <span class="van-ellipsis">{{ item.startDate }}</span>
            <span>-</span>
            <span class="van-ellipsis">{{ item.endDate }}</span>
          </div>

          <div class="item-text">{{ item.jobDescribe }}</div>
        </div>
      </div>
    </div>

    <div class="card" v-if="activityList.length">
      <div class="card-head van-hairline--bottom flex-box middle">
        <yxz-avatar
          class="card-icon"
          :src="require('@/assets/images/activity-icon.png')"
        />
        <span>实践活动</span>
      </div>

      <div class="card-main">
        <div class="item" v-for="item in activityList" :key="item.id">
          <div class="item-name flex-box middle van-ellipsis">
            <span class="van-ellipsis">{{ item.name }}</span>
          </div>

          <div class="item-text flex-box middle van-ellipsis">
            <span class="van-ellipsis">{{ item.startDate }}</span>
            <span>-</span>
            <span class="van-ellipsis">{{ item.endDate }}</span>
          </div>

          <div class="item-text">{{ item.content }}</div>
        </div>
      </div>
    </div>

    <div class="card" v-if="certificateList.length">
      <div class="card-head van-hairline--bottom flex-box middle">
        <yxz-avatar
          class="card-icon"
          :src="require('@/assets/images/certificate-icon.png')"
        />
        <span>荣誉证书</span>
      </div>

      <div class="card-main">
        <div class="item" v-for="item in certificateList" :key="item.id">
          <div class="item-name flex-box middle van-ellipsis">
            <span class="van-ellipsis">{{ item.certificateName }}</span>
          </div>

          <div class="item-text flex-box middle van-ellipsis">
            <span class="van-ellipsis">{{ item.startDate }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="myself.selfEvaluation">
      <div class="card-head van-hairline--bottom flex-box middle">
        <yxz-avatar
          class="card-icon"
          :src="require('@/assets/images/myself-icon.png')"
        />
        <span>自我描述</span>
      </div>

      <div class="card-main">
        <div class="item">
          <div class="item-text flex-box middle van-ellipsis">
            <span class="van-ellipsis" v-html="richText(myself.selfEvaluation)">
            </span>
          </div>
        </div>
      </div>
    </div>

    <yxz-avatar
      class="bottom-show"
      :src="require('@/assets/images/bottom-show.png')"
    />
  </div>
</template>

<script>
import { Sticky, Notify, Icon } from 'vant';
import { getStudentOnlineResume, getDstOnlineResume } from '@/api/api';
import Avatar from '@/components/Avatar';
import filters from '@/composables/filters';

export default {
  name: 'ResumeShare',

  setup() {
    const { hiddenTel, hiddenEmail, salaryText, eduText, richText } = filters();

    return {
      hiddenTel,
      hiddenEmail,
      salaryText,
      eduText,
      richText,
    };
  },

  data() {
    return {
      studentId: this.$route.query.id || this.$route.query.stuId,
      studentInfo: {},
      myself: {},
      preferList: [],
      educationList: [],
      experienceList: [],
      positionList: [],
      activityList: [],
      certificateList: [],
      searchFunc: null,
    };
  },

  created() {
    if (this.$route.path === '/stuonlineresume') {
      // 数据脱敏
      this.searchFunc = getDstOnlineResume;
    } else {
      // 数据不要脱敏
      this.searchFunc = getStudentOnlineResume;
    }

    this.getInfo();
  },

  methods: {
    getInfo() {
      if (!this.studentId) {
        return Notify({
          type: 'danger',
          message: '页面已过期，请重新进入',
        });
      }

      this.searchFunc({
        params: {
          studentId: this.studentId,
        },
        headers: {
          token: this.$route.query.token || '',
        },
      }).then((res) => {
        if (!res) return;

        this.studentInfo = res.studentInfo || {};
        this.myself = res.other || {};
        this.preferList = res.prefer || [];
        this.educationList = res.education || [];
        this.experienceList = res.experience || [];
        this.positionList = res.schoolExperience || [];
        this.activityList = res.active || [];
        this.certificateList = res.certificate || [];
      });
    },
  },

  components: {
    YxzAvatar: Avatar,
    [Sticky.name]: Sticky,
    [Icon.name]: Icon,
  },
};
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
  background: #fafaf7;
}

.student-info {
  padding: 10px 20px;
  background: #fff;
}

.avatar {
  width: 60px;
  height: 60px;
  flex: 0 0 60px;
  border-radius: 4px;
  overflow: hidden;
}

.avatar-right {
  margin-left: 10px;
  flex: 1;
}

.name {
  font-size: 22px;
}

.school {
  font-size: 14px;

  .school-logo {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    margin-right: 5px;
  }
}

.info-main {
  margin-bottom: 15px;
}

.info-line {
  margin: 5px 0;
  font-size: 14px;
  color: #333;

  .van-icon {
    font-size: 16px;
    margin-right: 5px;
  }
}

.card {
  margin: 10px;
  padding: 10px;
  background: #fff;
  border-radius: 8px;

  .card-head {
    padding: 10px 0;

    .card-icon {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
      margin-right: 5px;
    }
  }

  .item {
    padding: 10px 0;
  }

  .item-name {
    font-size: 14px;

    span + span {
      margin-left: 20px;
    }
  }

  .item-text {
    margin-top: 5px;
    font-size: 14px;
    color: #666;

    span + span {
      margin-left: 10px;
    }
  }
}

.bottom-show {
  margin: 20px 10px 40px;
}
</style>
